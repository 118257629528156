@charset "utf-8";
@import "./public";

@media screen and (min-width: 1160px) {
  section[class*="home-"] {
    max-width: 1160px;
    .todo-leftBox,
    .todo-rightBox {
      height: 300px;
    }
  }
  .topCarousel {
    margin-bottom: 40px;
  }
  .home-news-panel {
    height: 380px;
    max-width: 1160px;
    margin: 100px auto 0px auto !important;
    display: flex;
    justify-content: space-between;
    .news-banner {
      width: 50%;
      height: 380px;
      overflow: hidden;
      border-radius: 3px;
      position: relative;
      .swiper-container {
        height: 380px;
        .titleBottom {
          opacity: 0.5;
          position: absolute;
          line-height: 42px;
          height: 42px;
          width: 100%;
          font-size: 16px;
          padding-left: 15px;
          left: 0;
          bottom: 0;
          color: #fff;
          background: #000;
          z-index: 10;
        }
      }
      .swiper-pagination-1 {
        width: 30%;
        line-height: 40px;
        height: 40px;
        position: absolute;
        text-align: center;
        bottom: 0;
        right: 0;
        z-index: 15;
        .swiper-pagination-bullet {
          width: 10px;
          height: 10px;
          background: #fff;
        }
        .swiper-pagination-bullet-active {
          background: #ffae00;
          margin: 0 6px;
        }
      }
      img {
        width: auto;
        height: 100%;
        cursor: pointer;
      }
    }
    .news-list {
      width: 46%;
      margin-left: 4%;
      height: 380px;
      .more {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
        & > div:nth-of-type(1) {
          height: 40px;
          line-height: 40px;
          border-bottom: 2px solid #355e92;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 20px;
          font-family: fantasy;
          span {
            font-weight: 700;
            color: #355e92;
          }
          img {
            width: 35px;
            height: auto;
            margin-right: 10px;
          }
        }
        & > div:nth-of-type(2) {
          height: 40px;
          line-height: 40px;
          font-size: 20px;
          cursor: pointer;
          & > span:nth-of-type(2) {
            margin-left: 5px;
          }
        }
      }
      ul {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        li {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #333;
          font-size: 16px;
          margin: 6px 0;
          cursor: pointer;
          &:hover {
            color: #355e92;
          }
          & > span:nth-of-type(1) {
            white-space: nowrap;
            max-width: 80%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          & > span:nth-of-type(2) {
            font-size: 16px;
            color: #999;
          }
        }
      }
    }
  }
  .home-toDo {
    padding-bottom: 50px;
    .todo-leftBox,
    .todo-rightBox {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .todo-topic {
        font-size: 2.7rem;
        font-weight: 500;
        color: #333;
      }
      .todo-txtarea {
        width: 80%;
        font-size: 1.6rem;
        padding: 1% 0 0 0;
        font-weight: 500;
        color: #666;
        line-height: 2.8rem;
      }
      img {
        width: 85%;
        height: 100%;
      }
    }
    .todo-rightBox {
      overflow: hidden;
    }
  }
  .home-dynamic {
    .dynamic-panel > div {
      height: 10vh;
    }
  }
  .home-partner {
    .partner-topic {
      padding: 7rem 0 3rem 0;
    }
  }
  .home-contact {
    padding: 3% 0 2% 0;
  }
  .containerBottom {
    & > div {
      padding: 5rem 15% 0 15%;
    }
    .itemInfo {
      width: 30%;
      ul :after {
        content: "";
        width: "";
      }
    }
  }
  //关于我们
  .about-wrapper {
    .center-layout;
    & > div {
      width: 80%;
      margin: 20px auto;
      .companyProfile {
        height: 47rem;
      }
    }
  }
  //申请试用
  .applyForTrial-page {
    .formBody {
      width: 75%;
      margin: 10rem auto;
      .row {
        padding: 12rem 10rem 12rem 12rem;
        .lower {
          height: 3.5em;
        }
        .title {
          font-size: 20px !important;
          font-weight: 500;
          margin-bottom: 1.4em !important;
        }
        .rightFrame {
          img {
            height: 100% !important;
            margin: 0 auto !important;
          }
        }
      }
    }
  }
  //行业资讯
  .news-page .newsWrapper {
    width: 90%;
    max-width: 1000px;
    margin: 0 auto;
    .newsItem {
      height: auto;
      width: 100%;
      margin-bottom: 50px;
      display: flex;
      align-items: stretch;
      .newsLeftItem {
        width: 30rem;
        height: 200px;
        overflow: hidden;
        margin-right: 2rem;
        border-radius: 3px;
        background-color: #f0eaea80;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .newsRightItem {
        flex: 1;
        height: 200px;
        vertical-align: middle;
        position: relative;
        p {
          width: 100%;
          font-size: 21px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #333333;
          margin-bottom: 3rem;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          .omit;
          &:hover {
            color: #ff6700;
          }
        }

        .ItemContent {
          font-size: 1.3rem;
          max-height: 100px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          cursor: pointer;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;

          &:hover {
            color: #ff6700;
          }
        }
        .historyImg {
          height: 20px;
          position: absolute;
          bottom: 0;
          .views,
          .newsDate {
            display: inline-block;
            margin-right: 1.5rem;
            img {
              width: 1.5rem;
              height: 1.2rem;
              margin-right: 0.5rem;
            }
            span {
              font-size: 1rem;
              font-family: ArialMT;
              color: #909090;
              line-height: 1.17rem;
            }
          }
        }
      }
    }
  }
  //产品详情页
  .product-page {
    .product-banner {
      width: 100%;
      height: 52rem;
      img {
        width: 100%;
        height: 100%;
      }
    }

    #applyBox {
      width: 100%;
      max-width: 1200px;
      margin: 5rem auto;
    }

    .product-advantage {
      max-width: 1200px;
      width: 85%;
      margin: auto;
      .product-advantage-flex {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .product-advantage-img {
        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .product-sellingPoint {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      & > div {
        width: 100%;
        height: 400px;
        margin: 8rem 1rem;
        display: flex;
        align-items: center;
        .product-point-img {
          width: 50%;
          height: 85%;
          &:hover img {
            transform: scale(1.3);
            transition: all 1s ease 0s;
            -webkit-transform: scale(1.3);
            -webkit-transform: all 1s ease 0s;
          }
          img {
            display: block;
            width: 85%;
            border: 0;
            cursor: pointer;
            transform: scale(1);
            transition: all 1s ease 0s;
            -webkit-transform: scale(1);
            -webkit-transform: all 1s ease 0s;
          }
        }
        .product-point-list {
          display: flex;
          width: 50%;
          align-items: center;
          flex-wrap: wrap;
          .product-point-dot {
            margin: 15px 0;
            width: 100%;
            &::before {
              content: "";

              width: 1.6rem;
              height: 2.4rem;
              margin: -0.3rem 0.6rem 0 0;
              display: inline-block;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1024px) and (max-width: 1199px) {
  section[class*="home-"] {
    max-width: 950px;
    .todo-leftBox,
    .todo-rightBox {
      height: 300px;
    }
  }
  .topCarousel {
    margin-bottom: 40px;
  }
  .home-toDo {
    .todo-leftBox,
    .todo-rightBox {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .todo-topic {
        font-size: 2.7rem;
        font-weight: 500;
        color: #333;
      }
      .todo-txtarea {
        width: 80%;
        font-size: 1.6rem;
        padding: 1% 0 0 0;
        font-weight: 500;
        color: #666;
        line-height: 2.8rem;
      }
      img {
        width: 85%;
        height: 85%;
        margin: auto;
      }
    }
    .todo-rightBox {
      overflow: hidden;
    }
  }
  .home-dynamic {
    .dynamic-panel > div {
      height: 8vh;
    }
  }
  .home-partner {
    .partner-topic {
      padding: 7rem 0 3rem 0;
    }
  }
  .home-contact {
    padding: 3% 0 2% 0;
  }
  .containerBottom {
    & > div {
      padding: 4rem 15% 0 15%;
    }
    .itemInfo {
      width: 30%;
    }
  }
  //关于我们
  .about-wrapper {
    .center-layout;
    .companyProfile {
      height: 47rem;
    }
  }
  //下载
  .applyForTrial-page {
    .formBody {
      width: 80%;
      margin: 8rem auto;
      .row {
        padding: 12rem 10rem 12rem 12rem;
        .lower {
          height: 3.5em;
        }
        .title {
          font-size: 22px !important;
          margin-bottom: 1.2em !important;
        }
        .rightFrame {
          img {
            height: 100% !important;
            margin: 50% auto 0 auto !important;
          }
        }
      }
    }
  }
  //行业资讯
  .news-page .newsWrapper {
    width: 90%;
    max-width: 1000px;
    margin: 0 auto;
    .newsItem {
      height: auto;
      width: 100%;
      margin-bottom: 50px;
      display: flex;
      align-items: stretch;
      .newsLeftItem {
        width: 30rem;
        height: 200px;
        margin-right: 2rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .newsRightItem {
        flex: 1;
        height: 200px;
        vertical-align: middle;
        position: relative;
        p {
          width: 100%;
          font-size: 21px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #333333;
          margin-bottom: 3rem;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          .omit;
          &:hover {
            color: #ff6700;
          }
        }

        .ItemContent {
          font-size: 1.3rem;
          max-height: 100px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          cursor: pointer;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;

          &:hover {
            color: #ff6700;
          }
        }
        .historyImg {
          height: 20px;
          position: absolute;
          bottom: 0;
          .views,
          .newsDate {
            display: inline-block;
            margin-right: 1.5rem;
            img {
              width: 1.5rem;
              height: 1.2rem;
              margin-right: 0.5rem;
            }
            span {
              font-size: 1rem;
              font-family: ArialMT;
              color: #909090;
              line-height: 1.17rem;
            }
          }
        }
      }
    }
  }
  //产品详情页
  .product-page {
    .sectionTitle {
      margin: 5rem 0 3rem 0 !important;
    }
    .product-banner {
      width: 100%;
      height: auto;
      img {
        width: 100%;
        height: 100%;
      }
    }

    #applyBox {
      width: 100%;
      max-width: 1000px;
      margin: 3rem auto;
    }

    .product-advantage {
      max-width: 1200px;
      width: 85%;
      margin: auto;
      .product-advantage-flex {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .product-advantage-img {
        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .product-sellingPoint {
      width: 100%;
      max-width: 950px;
      margin: 0 auto;
      & > div {
        width: 100%;
        height: 400px;
        margin: 3rem 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .product-point-img {
          width: 55%;
          height: 85%;
          &:hover img {
            transform: scale(1.3);
            transition: all 1s ease 0s;
            -webkit-transform: scale(1.3);
            -webkit-transform: all 1s ease 0s;
          }
          img {
            display: block;
            width: 85%;
            border: 0;
            cursor: pointer;
            transform: scale(1);
            transition: all 1s ease 0s;
            -webkit-transform: scale(1);
            -webkit-transform: all 1s ease 0s;
          }
        }
        .product-point-list {
          display: flex;
          width: 40%;
          align-items: center;
          flex-wrap: wrap;
          .product-point-dot {
            margin: 10px 0;
            width: 100%;
            &::before {
              content: "";

              width: 1.6rem;
              height: 2.4rem;
              margin: -0.3rem 0.6rem 0 0;
              display: inline-block;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) {
  section[class*="home-"] {
    max-width: 760px;
    div[class*="Box"] {
      justify-content: center;
      padding: 2% !important;
    }
  }
  .topCarousel {
    margin-bottom: 15px;
  }
  .home-toDo {
    width: 770px;
    margin: 0 auto 50px auto !important;
    .todo-leftBox {
      height: 150px;
      .center-layout;
    }
    .todo-rightBox {
      overflow: hidden;
      height: 240px;
      .center-layout;
      img {
        width: 60%;
        height: calc(45vw);
      }
    }
  }
  .home-banner {
    height: 380px;
  }
  .home-dynamic {
    .dynamic-plate {
      padding: 0 5px;
    }
    .dynamic-panel > div {
      height: 8vh;
    }
  }
  .home-partner {
    .partner-topic {
      padding: 7rem 0 3rem 0;
    }
  }
  .home-contact {
    padding: 3% 0 2% 0;
  }
  .containerBottom {
    & > div {
      padding: 4rem 15% 0 15%;
    }
    .itemInfo {
      width: 30%;
    }
  }
  //关于我们
  .about-wrapper {
    .center-layout;
    & > div {
      width: 80%;
      .companyProfile {
        height: 47rem;
      }
      .ourHope {
        margin: 5rem 2% 8rem 2%;
        height: auto !important;
        .developImg p {
          font-size: 18px;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #333333;
          text-align: center;
        }
      }
    }
  }
  //下载
  .applyForTrial-page {
    .formBody {
      width: 70%;
      margin: 4rem auto;
      .row {
        padding: 5em 5em 2em 5em;
        .lower {
          height: 3.5em;
        }
        .title {
          font-size: 22px !important;
          margin-bottom: 1.2em !important;
        }
        .rightFrame {
          img {
            width: 100% !important;
            height: 25rem !important;
            margin: 40% auto !important;
          }
        }
      }
    }
  }
  //行业资讯
  .news-page {
    .news-bar {
      .bannerWrapper {
        height: 20vh !important;
      }
      .newsListNav {
        height: 6vh !important;
        position: relative !important;
        .maskLayer {
          background-color: #32333e !important;
          opacity: 0.8 !important;
        }
        .itemWrapper div {
          font-size: 16px !important;
        }
        .active {
          border-bottom: 0.33rem solid #fff !important;
        }
      }
    }
    .newsWrapper {
      width: 100%;
      box-sizing: border-box;
      padding: 16px 16px 0 16px;

      .newsItem {
        height: auto;
        width: 100%;
        margin-bottom: 16px;
        display: flex;
        flex-direction: row-reverse;
        align-items: stretch;
        .newsLeftItem {
          width: 30%;
          height: 7em;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .newsRightItem {
          flex: 1;
          height: 7em;
          vertical-align: middle;
          position: relative;
          p {
            width: 100%;
            font-size: 2rem;
            font-family: PingFang-SC-Medium, PingFang-SC;
            font-weight: 500;
            color: #333333;
            margin-bottom: 3rem;
            white-space: nowrap;
            .omit;
          }
          p:hover {
            color: #ff6700;
          }
          .ItemContent {
            font-size: 1.17rem;
            height: 3.5rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            cursor: pointer;
            .omit;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;

            &:hover {
              color: #ff6700;
            }
          }
          .historyImg {
            height: 2rem;
            position: absolute;
            top: 2.5rem;
            .views,
            .newsDate {
              display: inline-block;
              margin-right: 1.5rem;
              img {
                width: 1.5rem;
                height: 1.2rem;
                margin-right: 0.5rem;
              }
              span {
                font-size: 1rem;
                font-family: ArialMT;
                color: #909090;
                line-height: 1.17rem;
              }
            }
          }
        }
      }
    }
  }
  //产品详情页
  .product-page {
    .sectionTitle {
      margin: 5rem 0 3rem 0 !important;
    }
    .product-banner {
      width: 100%;
      height: auto;
      img {
        width: 100%;
        height: 100%;
      }
    }

    #applyBox {
      max-width: 85%;
      margin: 2rem auto;
    }

    .product-advantage {
      max-width: 85%;
      margin: auto;
      .product-advantage-flex {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 7rem;
      }
      .product-advantage-img {
        img {
          width: 100%;
          height: auto;
        }
      }
      .modeTwo {
        & > div {
          height: 22rem;
          margin: 0 0 6rem 0;
          .flexIcon {
            width: 6rem;
            height: 6rem;
            top: -3.5rem;
          }
          .flexTitle {
            margin: 4.5rem auto 1rem auto;
            font-size: 1.5em !important;
            width: 65%;
            min-height: 70px;
          }
          .flexContent {
            margin: 8% auto 0 auto;
            font-size: 2em;
            width: 90%;
            height: 50px;
            line-height: 50px;
          }
        }
      }
    }

    .product-sellingPoint {
      width: 100%;
      max-width: 950px;
      margin: 0 auto;
      & > div {
        width: 85%;
        height: 400px;
        margin: 3rem auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .product-point-img {
          width: 55%;
          height: 85%;
          &:hover img {
            transform: scale(1.3);
            transition: all 1s ease 0s;
            -webkit-transform: scale(1.3);
            -webkit-transform: all 1s ease 0s;
          }
          img {
            display: block;
            width: 85%;
            border: 0;
            cursor: pointer;
            transform: scale(1);
            transition: all 1s ease 0s;
            -webkit-transform: scale(1);
            -webkit-transform: all 1s ease 0s;
          }
        }
        .product-point-list {
          display: flex;
          width: 40%;
          align-items: center;
          flex-wrap: wrap;
          .product-point-dot {
            margin: 8px 0;
            width: 100%;
            &::before {
              content: "";

              width: 1.6rem;
              height: 2rem;
              margin: -0.3rem 1rem 0 0;
              display: inline-block;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 200px) and (max-width: 768px) {
  section[class*="home-"] {
    max-width: 100%;
    margin: 50px auto 50px auto !important;
    div[class*="Box"] {
      justify-content: center;
      padding: 2% !important;
    }
  }
  //首页
  .topCarousel {
    margin-bottom: 15px;
  }
  .home-aboutUs {
    .more-btn {
      margin: 60px auto 35px auto !important;
    }
  }

  .home-service {
    .service-wrapper {
      padding: 5px 15px !important;
      & > div {
        width: 80% !important;
        margin: 0 auto !important;
      }
    }
  }
  .home-CCHuaiHua {
    padding: 0px 20px !important;
  }
  .home-overview .flex-overview .overview-item {
    margin: 30px 0 !important;
    .description {
      padding: 8px 5px;
      width: 80% !important;
      color: black !important;
      background-color: #e9dfdf !important;
      border-radius: 10px !important;
      & > p:nth-of-type(1) {
        font-size: 17px;
      }
      p {
        text-align: center;
      }
    }
  }
  //行业资讯
  .news-page {
    .news-bar {
      .bannerWrapper {
        height: 20vh !important;
      }
      .newsListNav {
        height: 6vh !important;
        position: relative !important;
        .maskLayer {
          background-color: #32333e !important;
          opacity: 0.8 !important;
        }
        .itemWrapper div {
          font-size: 16px !important;
        }
        .active {
          border-bottom: 0.33rem solid #fff !important;
        }
      }
    }
    .newsWrapper {
      width: 100%;
      box-sizing: border-box;
      padding: 16px 16px 0 16px;

      .newsItem {
        height: auto;
        width: 100%;
        margin-bottom: 16px;
        display: flex;
        flex-direction: row-reverse;
        align-items: stretch;
        .newsLeftItem {
          width: 35%;
          height: 5em;
          img {
            width: 80px;
            height: 100%;
            float: right;
          }
        }
        .newsRightItem {
          flex: 1;
          height: 5em;
          vertical-align: middle;
          width: 75%;
          p {
            width: 100%;
            font-size: 1.5rem;
            font-family: PingFang-SC-Medium, PingFang-SC;
            font-weight: 500;
            color: #333333;
            margin-bottom: 6px;
            white-space: nowrap;
            .omit;
          }
          p:hover {
            color: #ff6700;
          }
          .ItemContent {
            font-size: 1.3rem;
            height: 3.5rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            cursor: pointer;
            .omit;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            padding-left: 7px;
            &:hover {
              color: #ff6700;
            }
          }
        }
      }
    }
  }
  //新闻详情
  .content {
    margin: 5px;
    img {
      width: 360px;
    }
  }
  //底部
  .topFrame {
    .itemInfo {
      text-align: center;
      margin-top: 10px;
      .phoneLine {
        text-align: center;
        b {
          font-size: 1.5rem;
        }
      }
      .phoneMonday {
        text-align: center;
      }
      ul {
        li {
          float: left;
          padding-left: 20px;
        }
      }
    }
  }
}
