@charset "utf-8";
#app {
  position: relative;
}
ul li {
  list-style-type: none;
}
.navContainer {
  width: 100%;
}
@media screen and (min-width: 200px) and (max-width: 768px) {
  .homePageContainer .topCarousel {
    width: 100vw;
    height: 78px;
  }
  .homePageContainer section[class*="home-"] {
    margin: 10rem auto 50px auto;
  }
}
@media screen and (min-width: 720px) {
  .homePageContainer .topCarousel {
    width: 100vw;
    height: auto;
  }
  .homePageContainer section[class*="home-"] {
    margin: 10rem auto 50px auto;
  }
}
.none-spacing {
  padding: 0 !important;
  margin: 0 !important;
}
.center-layout {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.omit {
  overflow: hidden;
  text-overflow: ellipsis;
}
.img-left {
  flex-direction: row;
}
.img-right {
  flex-direction: row-reverse !important;
}
.sectionTitle {
  border-radius: 3px;
  font-family: monospace;
  font-size: 3rem;
  font-weight: 600;
  color: #333;
  letter-spacing: 0.067rem;
  text-align: center;
  border-bottom: 1.2px solid #dbdada;
  max-width: 300px;
  margin: 0 auto 10px auto;
  padding-bottom: 6px;
}
.retouch::before {
  content: "";
  background: url("assets/left.png");
  transform: translate(-15%, 30%);
  display: inline-block;
  background-size: 30px;
  width: 30px;
  height: 30px;
}
.retouch::after {
  content: "";
  background: url("assets/right.png");
  transform: translate(5%, 5%);
  display: inline-block;
  background-size: 30px;
  width: 30px;
  height: 30px;
}
.has-view-bg {
  background-attachment: fixed;
  display: block;
  background-color: #fff;
  position: relative;
  z-index: 2;
  height: 100%;
}
.main-palace {
  position: relative;
  background-image: url("assets/view-bg.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 20;
}
.hoverStyle {
  cursor: pointer;
  transition: all 1s;
}
.hoverStyle:hover {
  transform: translateY(-5%);
  box-shadow: 0px 0px 15px 5px #d8d8d8;
  transition: transform 0.35s linear;
  box-shadow: 10px 16px 18px rgba(0, 0, 0, 0.25) !important;
  margin-top: -25px;
}
.hoverStyle:active {
  border: 1px solid goldenrod;
}
.grand {
  font-family: LiSu, "MicrosoftJhengHei", STHeiti, MingLiu !important;
  color: black;
}
@media screen and (min-width: 1160px) {
  section[class*="home-"] {
    max-width: 1160px;
  }
  section[class*="home-"] .todo-leftBox,
  section[class*="home-"] .todo-rightBox {
    height: 300px;
  }
  .topCarousel {
    margin-bottom: 40px;
  }
  .home-news-panel {
    height: 380px;
    max-width: 1160px;
    margin: 100px auto 0px auto !important;
    display: flex;
    justify-content: space-between;
  }
  .home-news-panel .news-banner {
    width: 50%;
    height: 380px;
    overflow: hidden;
    border-radius: 3px;
    position: relative;
  }
  .home-news-panel .news-banner .swiper-container {
    height: 380px;
  }
  .home-news-panel .news-banner .swiper-container .titleBottom {
    opacity: 0.5;
    position: absolute;
    line-height: 42px;
    height: 42px;
    width: 100%;
    font-size: 16px;
    padding-left: 15px;
    left: 0;
    bottom: 0;
    color: #fff;
    background: #000;
    z-index: 10;
  }
  .home-news-panel .news-banner .swiper-pagination-1 {
    width: 30%;
    line-height: 40px;
    height: 40px;
    position: absolute;
    text-align: center;
    bottom: 0;
    right: 0;
    z-index: 15;
  }
  .home-news-panel .news-banner .swiper-pagination-1 .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background: #fff;
  }
  .home-news-panel .news-banner .swiper-pagination-1 .swiper-pagination-bullet-active {
    background: #ffae00;
    margin: 0 6px;
  }
  .home-news-panel .news-banner img {
    width: auto;
    height: 100%;
    cursor: pointer;
  }
  .home-news-panel .news-list {
    width: 46%;
    margin-left: 4%;
    height: 380px;
  }
  .home-news-panel .news-list .more {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  .home-news-panel .news-list .more > div:nth-of-type(1) {
    height: 40px;
    line-height: 40px;
    border-bottom: 2px solid #355e92;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 20px;
    font-family: fantasy;
  }
  .home-news-panel .news-list .more > div:nth-of-type(1) span {
    font-weight: 700;
    color: #355e92;
  }
  .home-news-panel .news-list .more > div:nth-of-type(1) img {
    width: 35px;
    height: auto;
    margin-right: 10px;
  }
  .home-news-panel .news-list .more > div:nth-of-type(2) {
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    cursor: pointer;
  }
  .home-news-panel .news-list .more > div:nth-of-type(2) > span:nth-of-type(2) {
    margin-left: 5px;
  }
  .home-news-panel .news-list ul {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  .home-news-panel .news-list ul li {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333;
    font-size: 16px;
    margin: 6px 0;
    cursor: pointer;
  }
  .home-news-panel .news-list ul li:hover {
    color: #355e92;
  }
  .home-news-panel .news-list ul li > span:nth-of-type(1) {
    white-space: nowrap;
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .home-news-panel .news-list ul li > span:nth-of-type(2) {
    font-size: 16px;
    color: #999;
  }
  .home-toDo {
    padding-bottom: 50px;
  }
  .home-toDo .todo-leftBox,
  .home-toDo .todo-rightBox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .home-toDo .todo-leftBox .todo-topic,
  .home-toDo .todo-rightBox .todo-topic {
    font-size: 2.7rem;
    font-weight: 500;
    color: #333;
  }
  .home-toDo .todo-leftBox .todo-txtarea,
  .home-toDo .todo-rightBox .todo-txtarea {
    width: 80%;
    font-size: 1.6rem;
    padding: 1% 0 0 0;
    font-weight: 500;
    color: #666;
    line-height: 2.8rem;
  }
  .home-toDo .todo-leftBox img,
  .home-toDo .todo-rightBox img {
    width: 85%;
    height: 100%;
  }
  .home-toDo .todo-rightBox {
    overflow: hidden;
  }
  .home-dynamic .dynamic-panel > div {
    height: 10vh;
  }
  .home-partner .partner-topic {
    padding: 7rem 0 3rem 0;
  }
  .home-contact {
    padding: 3% 0 2% 0;
  }
  .containerBottom > div {
    padding: 5rem 15% 0 15%;
  }
  .containerBottom .itemInfo {
    width: 30%;
  }
  .containerBottom .itemInfo ul :after {
    content: "";
    width: "";
  }
  .about-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .about-wrapper > div {
    width: 80%;
    margin: 20px auto;
  }
  .about-wrapper > div .companyProfile {
    height: 47rem;
  }
  .applyForTrial-page .formBody {
    width: 75%;
    margin: 10rem auto;
  }
  .applyForTrial-page .formBody .row {
    padding: 12rem 10rem 12rem 12rem;
  }
  .applyForTrial-page .formBody .row .lower {
    height: 3.5em;
  }
  .applyForTrial-page .formBody .row .title {
    font-size: 20px !important;
    font-weight: 500;
    margin-bottom: 1.4em !important;
  }
  .applyForTrial-page .formBody .row .rightFrame img {
    height: 100% !important;
    margin: 0 auto !important;
  }
  .news-page .newsWrapper {
    width: 90%;
    max-width: 1000px;
    margin: 0 auto;
  }
  .news-page .newsWrapper .newsItem {
    height: auto;
    width: 100%;
    margin-bottom: 50px;
    display: flex;
    align-items: stretch;
  }
  .news-page .newsWrapper .newsItem .newsLeftItem {
    width: 30rem;
    height: 200px;
    overflow: hidden;
    margin-right: 2rem;
    border-radius: 3px;
    background-color: #f0eaea80;
  }
  .news-page .newsWrapper .newsItem .newsLeftItem img {
    width: 100%;
    height: 100%;
  }
  .news-page .newsWrapper .newsItem .newsRightItem {
    flex: 1;
    height: 200px;
    vertical-align: middle;
    position: relative;
  }
  .news-page .newsWrapper .newsItem .newsRightItem p {
    width: 100%;
    font-size: 21px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #333333;
    margin-bottom: 3rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .news-page .newsWrapper .newsItem .newsRightItem p:hover {
    color: #ff6700;
  }
  .news-page .newsWrapper .newsItem .newsRightItem .ItemContent {
    font-size: 1.3rem;
    max-height: 100px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
  .news-page .newsWrapper .newsItem .newsRightItem .ItemContent:hover {
    color: #ff6700;
  }
  .news-page .newsWrapper .newsItem .newsRightItem .historyImg {
    height: 20px;
    position: absolute;
    bottom: 0;
  }
  .news-page .newsWrapper .newsItem .newsRightItem .historyImg .views,
  .news-page .newsWrapper .newsItem .newsRightItem .historyImg .newsDate {
    display: inline-block;
    margin-right: 1.5rem;
  }
  .news-page .newsWrapper .newsItem .newsRightItem .historyImg .views img,
  .news-page .newsWrapper .newsItem .newsRightItem .historyImg .newsDate img {
    width: 1.5rem;
    height: 1.2rem;
    margin-right: 0.5rem;
  }
  .news-page .newsWrapper .newsItem .newsRightItem .historyImg .views span,
  .news-page .newsWrapper .newsItem .newsRightItem .historyImg .newsDate span {
    font-size: 1rem;
    font-family: ArialMT;
    color: #909090;
    line-height: 1.17rem;
  }
  .product-page .product-banner {
    width: 100%;
    height: 52rem;
  }
  .product-page .product-banner img {
    width: 100%;
    height: 100%;
  }
  .product-page #applyBox {
    width: 100%;
    max-width: 1200px;
    margin: 5rem auto;
  }
  .product-page .product-advantage {
    max-width: 1200px;
    width: 85%;
    margin: auto;
  }
  .product-page .product-advantage .product-advantage-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .product-page .product-advantage .product-advantage-img img {
    width: 100%;
    height: auto;
  }
  .product-page .product-sellingPoint {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
  .product-page .product-sellingPoint > div {
    width: 100%;
    height: 400px;
    margin: 8rem 1rem;
    display: flex;
    align-items: center;
  }
  .product-page .product-sellingPoint > div .product-point-img {
    width: 50%;
    height: 85%;
  }
  .product-page .product-sellingPoint > div .product-point-img:hover img {
    transform: scale(1.3);
    transition: all 1s ease 0s;
    -webkit-transform: scale(1.3);
    -webkit-transform: all 1s ease 0s;
  }
  .product-page .product-sellingPoint > div .product-point-img img {
    display: block;
    width: 85%;
    border: 0;
    cursor: pointer;
    transform: scale(1);
    transition: all 1s ease 0s;
    -webkit-transform: scale(1);
    -webkit-transform: all 1s ease 0s;
  }
  .product-page .product-sellingPoint > div .product-point-list {
    display: flex;
    width: 50%;
    align-items: center;
    flex-wrap: wrap;
  }
  .product-page .product-sellingPoint > div .product-point-list .product-point-dot {
    margin: 15px 0;
    width: 100%;
  }
  .product-page .product-sellingPoint > div .product-point-list .product-point-dot::before {
    content: "";
    width: 1.6rem;
    height: 2.4rem;
    margin: -0.3rem 0.6rem 0 0;
    display: inline-block;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1199px) {
  section[class*="home-"] {
    max-width: 950px;
  }
  section[class*="home-"] .todo-leftBox,
  section[class*="home-"] .todo-rightBox {
    height: 300px;
  }
  .topCarousel {
    margin-bottom: 40px;
  }
  .home-toDo .todo-leftBox,
  .home-toDo .todo-rightBox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .home-toDo .todo-leftBox .todo-topic,
  .home-toDo .todo-rightBox .todo-topic {
    font-size: 2.7rem;
    font-weight: 500;
    color: #333;
  }
  .home-toDo .todo-leftBox .todo-txtarea,
  .home-toDo .todo-rightBox .todo-txtarea {
    width: 80%;
    font-size: 1.6rem;
    padding: 1% 0 0 0;
    font-weight: 500;
    color: #666;
    line-height: 2.8rem;
  }
  .home-toDo .todo-leftBox img,
  .home-toDo .todo-rightBox img {
    width: 85%;
    height: 85%;
    margin: auto;
  }
  .home-toDo .todo-rightBox {
    overflow: hidden;
  }
  .home-dynamic .dynamic-panel > div {
    height: 8vh;
  }
  .home-partner .partner-topic {
    padding: 7rem 0 3rem 0;
  }
  .home-contact {
    padding: 3% 0 2% 0;
  }
  .containerBottom > div {
    padding: 4rem 15% 0 15%;
  }
  .containerBottom .itemInfo {
    width: 30%;
  }
  .about-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .about-wrapper .companyProfile {
    height: 47rem;
  }
  .applyForTrial-page .formBody {
    width: 80%;
    margin: 8rem auto;
  }
  .applyForTrial-page .formBody .row {
    padding: 12rem 10rem 12rem 12rem;
  }
  .applyForTrial-page .formBody .row .lower {
    height: 3.5em;
  }
  .applyForTrial-page .formBody .row .title {
    font-size: 22px !important;
    margin-bottom: 1.2em !important;
  }
  .applyForTrial-page .formBody .row .rightFrame img {
    height: 100% !important;
    margin: 50% auto 0 auto !important;
  }
  .news-page .newsWrapper {
    width: 90%;
    max-width: 1000px;
    margin: 0 auto;
  }
  .news-page .newsWrapper .newsItem {
    height: auto;
    width: 100%;
    margin-bottom: 50px;
    display: flex;
    align-items: stretch;
  }
  .news-page .newsWrapper .newsItem .newsLeftItem {
    width: 30rem;
    height: 200px;
    margin-right: 2rem;
  }
  .news-page .newsWrapper .newsItem .newsLeftItem img {
    width: 100%;
    height: 100%;
  }
  .news-page .newsWrapper .newsItem .newsRightItem {
    flex: 1;
    height: 200px;
    vertical-align: middle;
    position: relative;
  }
  .news-page .newsWrapper .newsItem .newsRightItem p {
    width: 100%;
    font-size: 21px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #333333;
    margin-bottom: 3rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .news-page .newsWrapper .newsItem .newsRightItem p:hover {
    color: #ff6700;
  }
  .news-page .newsWrapper .newsItem .newsRightItem .ItemContent {
    font-size: 1.3rem;
    max-height: 100px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
  .news-page .newsWrapper .newsItem .newsRightItem .ItemContent:hover {
    color: #ff6700;
  }
  .news-page .newsWrapper .newsItem .newsRightItem .historyImg {
    height: 20px;
    position: absolute;
    bottom: 0;
  }
  .news-page .newsWrapper .newsItem .newsRightItem .historyImg .views,
  .news-page .newsWrapper .newsItem .newsRightItem .historyImg .newsDate {
    display: inline-block;
    margin-right: 1.5rem;
  }
  .news-page .newsWrapper .newsItem .newsRightItem .historyImg .views img,
  .news-page .newsWrapper .newsItem .newsRightItem .historyImg .newsDate img {
    width: 1.5rem;
    height: 1.2rem;
    margin-right: 0.5rem;
  }
  .news-page .newsWrapper .newsItem .newsRightItem .historyImg .views span,
  .news-page .newsWrapper .newsItem .newsRightItem .historyImg .newsDate span {
    font-size: 1rem;
    font-family: ArialMT;
    color: #909090;
    line-height: 1.17rem;
  }
  .product-page .sectionTitle {
    margin: 5rem 0 3rem 0 !important;
  }
  .product-page .product-banner {
    width: 100%;
    height: auto;
  }
  .product-page .product-banner img {
    width: 100%;
    height: 100%;
  }
  .product-page #applyBox {
    width: 100%;
    max-width: 1000px;
    margin: 3rem auto;
  }
  .product-page .product-advantage {
    max-width: 1200px;
    width: 85%;
    margin: auto;
  }
  .product-page .product-advantage .product-advantage-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .product-page .product-advantage .product-advantage-img img {
    width: 100%;
    height: auto;
  }
  .product-page .product-sellingPoint {
    width: 100%;
    max-width: 950px;
    margin: 0 auto;
  }
  .product-page .product-sellingPoint > div {
    width: 100%;
    height: 400px;
    margin: 3rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .product-page .product-sellingPoint > div .product-point-img {
    width: 55%;
    height: 85%;
  }
  .product-page .product-sellingPoint > div .product-point-img:hover img {
    transform: scale(1.3);
    transition: all 1s ease 0s;
    -webkit-transform: scale(1.3);
    -webkit-transform: all 1s ease 0s;
  }
  .product-page .product-sellingPoint > div .product-point-img img {
    display: block;
    width: 85%;
    border: 0;
    cursor: pointer;
    transform: scale(1);
    transition: all 1s ease 0s;
    -webkit-transform: scale(1);
    -webkit-transform: all 1s ease 0s;
  }
  .product-page .product-sellingPoint > div .product-point-list {
    display: flex;
    width: 40%;
    align-items: center;
    flex-wrap: wrap;
  }
  .product-page .product-sellingPoint > div .product-point-list .product-point-dot {
    margin: 10px 0;
    width: 100%;
  }
  .product-page .product-sellingPoint > div .product-point-list .product-point-dot::before {
    content: "";
    width: 1.6rem;
    height: 2.4rem;
    margin: -0.3rem 0.6rem 0 0;
    display: inline-block;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) {
  section[class*="home-"] {
    max-width: 760px;
  }
  section[class*="home-"] div[class*="Box"] {
    justify-content: center;
    padding: 2% !important;
  }
  .topCarousel {
    margin-bottom: 15px;
  }
  .home-toDo {
    width: 770px;
    margin: 0 auto 50px auto !important;
  }
  .home-toDo .todo-leftBox {
    height: 150px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .home-toDo .todo-rightBox {
    overflow: hidden;
    height: 240px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .home-toDo .todo-rightBox img {
    width: 60%;
    height: calc(45vw);
  }
  .home-banner {
    height: 380px;
  }
  .home-dynamic .dynamic-plate {
    padding: 0 5px;
  }
  .home-dynamic .dynamic-panel > div {
    height: 8vh;
  }
  .home-partner .partner-topic {
    padding: 7rem 0 3rem 0;
  }
  .home-contact {
    padding: 3% 0 2% 0;
  }
  .containerBottom > div {
    padding: 4rem 15% 0 15%;
  }
  .containerBottom .itemInfo {
    width: 30%;
  }
  .about-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .about-wrapper > div {
    width: 80%;
  }
  .about-wrapper > div .companyProfile {
    height: 47rem;
  }
  .about-wrapper > div .ourHope {
    margin: 5rem 2% 8rem 2%;
    height: auto !important;
  }
  .about-wrapper > div .ourHope .developImg p {
    font-size: 18px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #333333;
    text-align: center;
  }
  .applyForTrial-page .formBody {
    width: 70%;
    margin: 4rem auto;
  }
  .applyForTrial-page .formBody .row {
    padding: 5em 5em 2em 5em;
  }
  .applyForTrial-page .formBody .row .lower {
    height: 3.5em;
  }
  .applyForTrial-page .formBody .row .title {
    font-size: 22px !important;
    margin-bottom: 1.2em !important;
  }
  .applyForTrial-page .formBody .row .rightFrame img {
    width: 100% !important;
    height: 25rem !important;
    margin: 40% auto !important;
  }
  .news-page .news-bar .bannerWrapper {
    height: 20vh !important;
  }
  .news-page .news-bar .newsListNav {
    height: 6vh !important;
    position: relative !important;
  }
  .news-page .news-bar .newsListNav .maskLayer {
    background-color: #32333e !important;
    opacity: 0.8 !important;
  }
  .news-page .news-bar .newsListNav .itemWrapper div {
    font-size: 16px !important;
  }
  .news-page .news-bar .newsListNav .active {
    border-bottom: 0.33rem solid #fff !important;
  }
  .news-page .newsWrapper {
    width: 100%;
    box-sizing: border-box;
    padding: 16px 16px 0 16px;
  }
  .news-page .newsWrapper .newsItem {
    height: auto;
    width: 100%;
    margin-bottom: 16px;
    display: flex;
    flex-direction: row-reverse;
    align-items: stretch;
  }
  .news-page .newsWrapper .newsItem .newsLeftItem {
    width: 30%;
    height: 7em;
  }
  .news-page .newsWrapper .newsItem .newsLeftItem img {
    width: 100%;
    height: 100%;
  }
  .news-page .newsWrapper .newsItem .newsRightItem {
    flex: 1;
    height: 7em;
    vertical-align: middle;
    position: relative;
  }
  .news-page .newsWrapper .newsItem .newsRightItem p {
    width: 100%;
    font-size: 2rem;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #333333;
    margin-bottom: 3rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .news-page .newsWrapper .newsItem .newsRightItem p:hover {
    color: #ff6700;
  }
  .news-page .newsWrapper .newsItem .newsRightItem .ItemContent {
    font-size: 1.17rem;
    height: 3.5rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .news-page .newsWrapper .newsItem .newsRightItem .ItemContent:hover {
    color: #ff6700;
  }
  .news-page .newsWrapper .newsItem .newsRightItem .historyImg {
    height: 2rem;
    position: absolute;
    top: 2.5rem;
  }
  .news-page .newsWrapper .newsItem .newsRightItem .historyImg .views,
  .news-page .newsWrapper .newsItem .newsRightItem .historyImg .newsDate {
    display: inline-block;
    margin-right: 1.5rem;
  }
  .news-page .newsWrapper .newsItem .newsRightItem .historyImg .views img,
  .news-page .newsWrapper .newsItem .newsRightItem .historyImg .newsDate img {
    width: 1.5rem;
    height: 1.2rem;
    margin-right: 0.5rem;
  }
  .news-page .newsWrapper .newsItem .newsRightItem .historyImg .views span,
  .news-page .newsWrapper .newsItem .newsRightItem .historyImg .newsDate span {
    font-size: 1rem;
    font-family: ArialMT;
    color: #909090;
    line-height: 1.17rem;
  }
  .product-page .sectionTitle {
    margin: 5rem 0 3rem 0 !important;
  }
  .product-page .product-banner {
    width: 100%;
    height: auto;
  }
  .product-page .product-banner img {
    width: 100%;
    height: 100%;
  }
  .product-page #applyBox {
    max-width: 85%;
    margin: 2rem auto;
  }
  .product-page .product-advantage {
    max-width: 85%;
    margin: auto;
  }
  .product-page .product-advantage .product-advantage-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 7rem;
  }
  .product-page .product-advantage .product-advantage-img img {
    width: 100%;
    height: auto;
  }
  .product-page .product-advantage .modeTwo > div {
    height: 22rem;
    margin: 0 0 6rem 0;
  }
  .product-page .product-advantage .modeTwo > div .flexIcon {
    width: 6rem;
    height: 6rem;
    top: -3.5rem;
  }
  .product-page .product-advantage .modeTwo > div .flexTitle {
    margin: 4.5rem auto 1rem auto;
    font-size: 1.5em !important;
    width: 65%;
    min-height: 70px;
  }
  .product-page .product-advantage .modeTwo > div .flexContent {
    margin: 8% auto 0 auto;
    font-size: 2em;
    width: 90%;
    height: 50px;
    line-height: 50px;
  }
  .product-page .product-sellingPoint {
    width: 100%;
    max-width: 950px;
    margin: 0 auto;
  }
  .product-page .product-sellingPoint > div {
    width: 85%;
    height: 400px;
    margin: 3rem auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .product-page .product-sellingPoint > div .product-point-img {
    width: 55%;
    height: 85%;
  }
  .product-page .product-sellingPoint > div .product-point-img:hover img {
    transform: scale(1.3);
    transition: all 1s ease 0s;
    -webkit-transform: scale(1.3);
    -webkit-transform: all 1s ease 0s;
  }
  .product-page .product-sellingPoint > div .product-point-img img {
    display: block;
    width: 85%;
    border: 0;
    cursor: pointer;
    transform: scale(1);
    transition: all 1s ease 0s;
    -webkit-transform: scale(1);
    -webkit-transform: all 1s ease 0s;
  }
  .product-page .product-sellingPoint > div .product-point-list {
    display: flex;
    width: 40%;
    align-items: center;
    flex-wrap: wrap;
  }
  .product-page .product-sellingPoint > div .product-point-list .product-point-dot {
    margin: 8px 0;
    width: 100%;
  }
  .product-page .product-sellingPoint > div .product-point-list .product-point-dot::before {
    content: "";
    width: 1.6rem;
    height: 2rem;
    margin: -0.3rem 1rem 0 0;
    display: inline-block;
  }
}
@media screen and (min-width: 200px) and (max-width: 768px) {
  section[class*="home-"] {
    max-width: 100%;
    margin: 50px auto 50px auto !important;
  }
  section[class*="home-"] div[class*="Box"] {
    justify-content: center;
    padding: 2% !important;
  }
  .topCarousel {
    margin-bottom: 15px;
  }
  .home-aboutUs .more-btn {
    margin: 60px auto 35px auto !important;
  }
  .home-service .service-wrapper {
    padding: 5px 15px !important;
  }
  .home-service .service-wrapper > div {
    width: 80% !important;
    margin: 0 auto !important;
  }
  .home-CCHuaiHua {
    padding: 0px 20px !important;
  }
  .home-overview .flex-overview .overview-item {
    margin: 30px 0 !important;
  }
  .home-overview .flex-overview .overview-item .description {
    padding: 8px 5px;
    width: 80% !important;
    color: black !important;
    background-color: #e9dfdf !important;
    border-radius: 10px !important;
  }
  .home-overview .flex-overview .overview-item .description > p:nth-of-type(1) {
    font-size: 17px;
  }
  .home-overview .flex-overview .overview-item .description p {
    text-align: center;
  }
  .news-page .news-bar .bannerWrapper {
    height: 20vh !important;
  }
  .news-page .news-bar .newsListNav {
    height: 6vh !important;
    position: relative !important;
  }
  .news-page .news-bar .newsListNav .maskLayer {
    background-color: #32333e !important;
    opacity: 0.8 !important;
  }
  .news-page .news-bar .newsListNav .itemWrapper div {
    font-size: 16px !important;
  }
  .news-page .news-bar .newsListNav .active {
    border-bottom: 0.33rem solid #fff !important;
  }
  .news-page .newsWrapper {
    width: 100%;
    box-sizing: border-box;
    padding: 16px 16px 0 16px;
  }
  .news-page .newsWrapper .newsItem {
    height: auto;
    width: 100%;
    margin-bottom: 16px;
    display: flex;
    flex-direction: row-reverse;
    align-items: stretch;
  }
  .news-page .newsWrapper .newsItem .newsLeftItem {
    width: 35%;
    height: 5em;
  }
  .news-page .newsWrapper .newsItem .newsLeftItem img {
    width: 80px;
    height: 100%;
    float: right;
  }
  .news-page .newsWrapper .newsItem .newsRightItem {
    flex: 1;
    height: 5em;
    vertical-align: middle;
    width: 75%;
  }
  .news-page .newsWrapper .newsItem .newsRightItem p {
    width: 100%;
    font-size: 1.5rem;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #333333;
    margin-bottom: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .news-page .newsWrapper .newsItem .newsRightItem p:hover {
    color: #ff6700;
  }
  .news-page .newsWrapper .newsItem .newsRightItem .ItemContent {
    font-size: 1.3rem;
    height: 3.5rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    padding-left: 7px;
  }
  .news-page .newsWrapper .newsItem .newsRightItem .ItemContent:hover {
    color: #ff6700;
  }
  .content {
    margin: 5px;
  }
  .content img {
    width: 360px;
  }
  .topFrame .itemInfo {
    text-align: center;
    margin-top: 10px;
  }
  .topFrame .itemInfo .phoneLine {
    text-align: center;
  }
  .topFrame .itemInfo .phoneLine b {
    font-size: 1.5rem;
  }
  .topFrame .itemInfo .phoneMonday {
    text-align: center;
  }
  .topFrame .itemInfo ul li {
    float: left;
    padding-left: 20px;
  }
}
