@charset "utf-8";

ul li {
  list-style-type: none;
}
.navContainer {
  width: 100%;
}
@media screen and (min-width: 200px) and (max-width: 768px) {
  .homePageContainer {
    .topCarousel {
      width: 100vw;
      height: 78px;
    }
    section[class*="home-"] {
      margin: 10rem auto 50px auto;
    }
  }
}

@media screen and (min-width: 720px) {
  .homePageContainer {
    .topCarousel {
      width: 100vw;
      height: auto;
    }
    section[class*="home-"] {
      margin: 10rem auto 50px auto;
    }
  }
}
.none-spacing {
  padding: 0 !important;
  margin: 0 !important;
}
.center-layout {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.omit {
  overflow: hidden;
  text-overflow: ellipsis;
}
.img-left {
  flex-direction: row;
}
.img-right {
  flex-direction: row-reverse !important;
}
.sectionTitle {
  border-radius: 3px;
  font-family: monospace;
  font-size: 3rem;
  font-weight: 600;
  color: #333;
  letter-spacing: 0.067rem;
  text-align: center;
  border-bottom: 1.2px solid #dbdada;
  max-width: 300px;
  margin: 0 auto 10px auto;
  padding-bottom: 6px;
}
.retouch {
  &::before {
    content: "";
    background: url("../assets/left.png");
    transform: translate(-15%, 30%);
    display: inline-block;
    background-size: 30px;
    width: 30px;
    height: 30px;
  }
  &::after {
    content: "";
    background: url("../assets/right.png");
    transform: translate(5%, 5%);
    display: inline-block;
    background-size: 30px;
    width: 30px;
    height: 30px;
  }
}
.has-view-bg {
  background-attachment: fixed;
  display: block;
  // background-color: rgba(255, 0, 0, 0);
  background-color: #fff;
  position: relative;
  z-index: 2;
  height: 100%;
}
.main-palace {
  position: relative;
  background-image: url("../assets/view-bg.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 20;
}
.hoverStyle {
  cursor: pointer;
  transition: all 1s;
  &:hover {
    transform: translateY(-5%);
    box-shadow: 0px 0px 15px 5px #d8d8d8;
    transition: transform 0.35s linear;
    box-shadow: 10px 16px 18px rgba(0, 0, 0, 0.25) !important;
    margin-top: -25px;
  }
  &:active {
    border: 1px solid goldenrod;
  }
}
.grand {
  font-family: LiSu, "MicrosoftJhengHei", STHeiti, MingLiu !important;
  color: black;
}
